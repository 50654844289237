import React, { useContext, useEffect } from 'react'

import Card from '../../card'
import TitleCard from '../../titleCard'
import Appointments from './../appointments'
import LabelTable from '../../labelTable'

import ClientContext from '../../../services/client';
import { defaults } from '../../../lib/defaults';
import { get } from '../../../utils/objects';
import LoadingIndicator from '../../LoadingIndicator';
import Button from '../../button'
import SendClientInfoButton from '../sendClientInfoButton';
import DialogContext from '../../../context/dialog'
import Personal from '../../info/personal'
import MessagesContext from '../../../context/messages'
import Messages from '../../messages'
import Icon from '../../icon';
import NotesForm from '../notesForm';
import Education from '../../info/education';
import Family from '../../info/family';
import Employment from '../../info/employment';
import Interests from '../../info/interests';
import monthArray from '../../date/monthArray';
import Final from '../../info/final'


const getAddress = data => {
  const address = get(data, 'address');

  if (!address) {
    return '';
  }

  const {
    city,
    state,
    street1,
    street2,
    zip
  } = address;

  if (!(street1 + street2 + city + state + zip)) {
    return '';
  }

  return (<div>{street1}{street2 && (<br />)}{street2 && street2}<br/>{`${city || ''}, ${state || ''} ${zip || ''}`}</div>)
}

const getDob = data => {
  const month = get(data, 'birthMonth');
  const year = get(data, 'birthYear');
  const returnMonth = month ? monthArray[month].label : '';
  const returnYear = year ? year : '';
  return returnMonth + ' ' + returnYear;
};

const getReferred = data => {
  let referred =  get(data, 'final.referred')
  let other = get(data, 'final.referredOther')
  return referred === 'other' ? other : get(data, 'final.referredLabel');
}

const getJobs = data => get(data, 'employment').map((item, i) => (
  i == 0 ? {
    label: `Current or prev Job`,
    value:
      {
        title: {
          label: 'Title',
          value: get(item, 'title')
        },
        code: {
          label: 'Job code',
          value: get(item, 'codeLabel')
        },
        liked: {
          label: 'How do you like this job?',
          value: get(item, 'liked')
        },
        years: {
          label: '# of Years',
          value: get(item, 'years')
        },
        description: {
          label: 'Description',
          value: get(item, 'description')
        }
      }
  } :
    {
      label: `Prev job ${i}`,
      value:
      {
        title: {
          label: 'Title',
          value: get(item, 'title')
        },
        liked: {
          label: 'How do you like this job?',
          value: get(item, 'liked')
        },
        years: {
          label: '# of Years',
          value: get(item, 'years')
        }
      }
    }
));

const getInterests = data => get(data, 'interests').map((item, i) => (
  {
    label: `Interest ${i + 1}`,
    value: {
      title: {
        label: 'Interest',
        value: get(item, 'title')
      },
      duration: {
        label: 'Number of Years',
        value: get(item, 'duration')
      }
    }
  }
));

const getParents = data => get(data, 'family.parents').map((parent, i) => (
  {
    label: `Parent ${i + 1}`,
    value: {
      occupation: {
        label: 'Occupation',
        value: get(parent, 'occupation')
      },
      education: {
        label: 'Education Completed',
        value: get(parent, 'education')
      }
    }
  }
));

const getContacts = data => get(data, 'contacts').map((contact, i) => (
  {
    label: `Additional Contact ${i + 1}`,
    value: {
      name: {
        label: 'Name',
        value: get(contact, 'name')
      },
      email: {
        label: 'Email',
        value: (<a href={`mailto:${get(contact, 'email')}`}>{get(contact, 'email')}</a>)
      },
      phone: {
        label: 'Phone',
        value: (<a href={`tel:${get(contact, 'phone')}`}>{get(contact, 'phone')}</a>)
      },
      preferred: {
        label: 'Preferred Contact Method',
        value: get(contact, 'preferred')
      },
    }
  }
));

const getFamilyTested = data => get(data, 'family.familyTested').map((familyTested, i) => (
  {
    label: `Family Member ${i + 1}`,
    value: {
      name: {
        label: 'Name',
        value: get(familyTested, 'name')
      },
      location: {
        label: 'Location',
        value: get(familyTested, 'city')
      },
      date: {
        label: 'Year (if known)',
        value: get(familyTested, 'year')
      }
    }
  }
));

const getReferralData = data => {
  const output = {
    referred: {
      label: 'How did you hear about JOCRF?',
      value:  getReferred(data)
    },
  }
  const referredValue = get(data, 'final.referred');

  if (referredValue) {
    if (referredValue === 'ad') {
      output.referredAd = {
        label: 'Where did you see the ad?',
        value: get(data, 'final.referredAd')
      }
    } else {
      Object.values(defaults.referred).forEach((item) => {
        if (item.value === referredValue && item.collectContactInfo) {
          output.referralName = {
            label: 'Referral name',
            value: get(data, 'final.referredName')
          };
          output.referralEmail = {
            label: 'Referral email',
            value: get(data, 'final.referredEmail')
          };
        }
      });
    }
  }

  output.clientNotes = {
    label: 'Are there any further details you\'d like to share about how you found us?',
    value: get(data, 'final.clientNotes')
  };

  return output;
}

const normalizeInfo = data => (
  {
    contact: {
      name: {
        label: 'Name',
        value: get(data, 'name')
      },
      address: {
        label: 'Address',
        value: getAddress(data)
      },
      email: {
        label: 'Email',
        value: (<a href={`mailto:${get(data, 'email')}`}>{get(data, 'email')}</a>)
      },
      phone: {
        label: 'Phone',
        value: (<a href={`tel:${get(data, 'phone')}`}>{get(data, 'phone')}</a>)
      },
      preferred: {
        label: 'Preferred Contact Method',
        value: get(data, 'preferred')
      },
      consentNewsletter: {
        label: 'Newsletter Consent',
        value: get(data, 'consentNewsletter') ? 'Yes' : 'No',
      },
      sex: {
        label: 'Sex',
        value: get(data, 'sex')
      },
      pronouns: {
        label: 'Preferred Pronouns',
        value: get(data, 'pronouns')
      },
      dob: {
        label: 'Date of Birth',
        value: getDob(data)
      },
      testTakerNotes: {
        label: 'Is there anything about the person testing that you\'d like us to be aware of?',
        value: get(data, 'testTakerNotes')
      },
      ...getReferralData(data)
    },
    additionalContacts: getContacts(data),
    education: {
      gradeCompleted: {
        label: 'Last Grade Completed',
        value: get(data, 'education.gradeCompleted')
      },
      lastSchool: {
        label: 'Last School',
        value: get(data, 'education.lastSchool')
      },
      degree: {
        label: 'Degree Earned',
        value: get(data, 'education.degree')
      },
      fieldOfStudy: {
        label: 'Major Field of Study (college or later)',
        value: get(data, 'education.fieldOfStudy')
      },
      majorCode: {
        label: 'Major code',
        value: get(data, 'education.majorCodeLabel')
      },
      likelyToChangeMajor: {
        label: 'How likely are you to change your major',
        value: get(data, 'education.likelyToChangeMajor')
      },
      changeMajorTo: {
        label: 'If likely, do you have something in mind?',
        value: get(data, 'education.changeMajorTo')
      },
      fieldOfStudy2: {
        label: 'Second Major or Minor',
        value: get(data, 'education.fieldOfStudy2')
      },
      likelyToContinueEdu: {
        label: 'How likely are you to continue your education?',
        value: get(data, 'education.likelyToContinueEdu')
      },
      speakOtherLangAtHome: {
        label: 'Do you speak a language other than English at home?',
        value: get(data, 'education.speakOtherLangAtHome')
      },
      languageSpokenAtHome: {
        label: 'What language do you speak at home?',
        value: get(data, 'education.languageSpokenAtHomeLabel')
      }
    },
    family: {
      parents: getParents(data),
      twinTriplet: {
        label: 'Are you a twin or triplet?',
        value: get(data, 'family.twinOrTriplet')
      },
      familyTested: getFamilyTested(data)
    },
    employment: getJobs(data),
    interests: getInterests(data)
  }
);

const ClientInfo = ({
  id,
  info,
  appointments,
  addAppointment,
  completeAppointment,
  rescheduleAppointment
}) => {
  const clientAPI = useContext(ClientContext);
  const dialog = useContext(DialogContext);
  const { messages, setMessages } = useContext(MessagesContext);

  const { isResolved } = clientAPI;

  // Clear messages when page loads
  useEffect(() => {
    if (isResolved) {
      setMessages([]);
    }
  }, [isResolved])

  if (!isResolved) {
    return (
      <Card modifier="card--page">
        <LoadingIndicator />
      </Card>
    );
  }

  const data = normalizeInfo(clientAPI.data);

  const notes = get(clientAPI, 'data.notes');

  const openNotes = (e) => {
    // Clear messages so they don't appear within the modal.
    setMessages([]);
    dialog.openWith({
      content: <NotesForm
          id={id}
          notesDefault={notes}
          onSuccess={() => {
            clientAPI.load()
            .then(() => {dialog.close()});
          }}
          onCancel={() => {
            dialog.close()
          }}
      />,
    });
  }

  const openForm = (e) => {
    // Clear messages so they don't appear within the modal.
    setMessages([]);
    dialog.openWith({
      content: <Personal
        id={id}
        heading={'Edit Contacts'}
        onSuccess={() => {
          clientAPI.load()
            .then(() => {dialog.close()});
        }}
        onCancel={() => {
          dialog.close()
        }}
      />,
    });
  }

  const openEducation = (e) => {
    // Clear messages so they don't appear within the modal.
    setMessages([]);
    dialog.openWith({
      content: <Education
          id={id}
          heading={'Edit Education'}
          showMajorCode={true}
          onSuccess={() => {
            clientAPI.load()
            .then(() => {dialog.close()});
          }}
          onCancel={() => {
            dialog.close()
          }}
      />,
    });
  }

  const openFamily = (e) => {
    // Clear messages so they don't appear within the modal.
    setMessages([]);
    dialog.openWith({
      content: <Family
          id={id}
          heading={'Edit Family'}
          onSuccess={() => {
            clientAPI.load()
            .then(() => {dialog.close()});
          }}
          onCancel={() => {
            dialog.close()
          }}
      />,
    });
  }

  const openEmployment = (e) => {
    // Clear messages so they don't appear within the modal.
    setMessages([]);
    dialog.openWith({
      content: <Employment
          id={id}
          heading={'Edit Employment'}
          showJobCode={true}
          onSuccess={() => {
            clientAPI.load()
            .then(() => {dialog.close()});
          }}
          onCancel={() => {
            dialog.close()
          }}
      />,
    });
  }

  const openInterests = (e) => {
    // Clear messages so they don't appear within the modal.
    setMessages([]);
    dialog.openWith({
      content: <Interests
          id={id}
          heading={'Edit Interests / Hobbies'}
          onSuccess={() => {
            clientAPI.load()
            .then(() => {dialog.close()});
          }}
          onCancel={() => {
            dialog.close()
          }}
      />,
    });
  }

  return (
    <Card modifier="card--page">
      <Messages />
      <TitleCard title="Appointments">
        <Appointments
          rows={appointments}
          add={addAppointment}
          complete={completeAppointment}
          reschedule={rescheduleAppointment}
        />
        <div className="button-group">
          <SendClientInfoButton id={id} />
        </div>
      </TitleCard>
      <TitleCard title={<>
        <div>Notes</div>
        { notes.length ?
        <Button theme="primary" onClick={openNotes}>Edit</Button> : ''}
      </>}>
        { notes.length ?
            <div className={'container-panel display-linebreak'}>
              { notes }
            </div> :
            <Button theme="secondary" onClick={openNotes}>
              <Icon theme="secondary" icon="note" modifier="plus" />
              <span>
                Add Notes
              </span>
            </Button>
        }
      </TitleCard>
      <TitleCard title={<>
        <div>Contact</div>
        <Button theme="primary" onClick={openForm}>Edit</Button>
      </>}>
        <LabelTable qa="client-contact-table" rows={data.contact} />
        {(data.additionalContacts).map((item, i) => (
          <LabelTable
            key={i}
            label={item.label}
            rows={item.value}
          />))}
      </TitleCard>
      <TitleCard title={<>
        <div>Education</div>
        <Button theme="primary" onClick={openEducation}>Edit</Button>
      </>}>
        <LabelTable rows={data.education} />
      </TitleCard>
      <TitleCard title={<>
        <div>Family</div>
        <Button theme="primary" onClick={openFamily}>Edit</Button>
      </>}>
        {(data.family.parents).map((item, i) => (
          <LabelTable
            key={i}
            label={item.label}
            rows={item.value}
          />))}
        <LabelTable
          label={"Twin or Triplet?"}
          rows={[data.family.twinTriplet]}
        />
        {(data.family.familyTested).map((item, i) => (
          <LabelTable
            key={i}
            label={item.label}
            rows={item.value}
          />))}
      </TitleCard>
      <TitleCard title={<>
        <div>Employment</div>
        <Button theme="primary" onClick={openEmployment}>Edit</Button>
      </>}>
      {(data.employment).map((item, i) => (
        <LabelTable
          key={i}
          label={item.label}
          rows={item.value}
        />
      ))}
        </TitleCard>

      <TitleCard title={<>
        <div>Interests</div>
        <Button theme="primary" onClick={openInterests}>Edit</Button>
      </>}>
        {(data.interests).map((item, i) => (
          <LabelTable
            key={i}
            label={item.label}
            rows={item.value}
          />))}
      </TitleCard>
    </Card>
  )
}

export default ClientInfo
